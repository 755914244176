<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterFeUserPollVotes">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('feUser.id')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.poll" id="filter_poll" :label="$t('feUser.poll')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.pollOption" id="filter_pollOption"
                         :label="$t('feUser.poll_option')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.feUser_email" id="filter_feUser_email"
                         :label="$t('feUser.user_email')"></app-input>
            </div>
            <div class="col-lg-4">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('feUser.filter.search') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" @click.prevent="resetFilters">
                {{ $t('feUser.filter.reset_filter') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" @click.prevent="exportPollVotes">
                {{ $t('feUser.filter.export') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../../form/inputs/Input'
import FeUserPollVoteFilterModel from '../../../model/feUser/FeUserPollVoteFilter'
import DownloadFileService from '../../../services/eshop/DownloadFileService'
import NotifyService from '../../../services/NotifyService'

export default {
  name: 'FeUserPollVoteFilter',
  data () {
    return {
      filter: this._.cloneDeep(FeUserPollVoteFilterModel)
    }
  },
  components: {
    appInput: Input
  },
  methods: {
    filterFeUserPollVotes () {
      this.$store.commit('feUserPollVote/setPage', 1)
      this.$store.commit('feUserPollVote/setFilter', this.filter)
      this.$store.dispatch('feUserPollVote/fetch')
    },
    toggleAdvancedFilter () {
      this.advancedFilter = !this.advancedFilter
    },
    resetFilters () {
      this.filter = this._.cloneDeep(FeUserPollVoteFilterModel)
      this.filterFeUserPollVotes()
    },
    exportPollVotes () {
      this.$store.dispatch('feUserPollVote/export')
        .then((res) => {
          DownloadFileService.download(res, 'anketa_', '.csv')
        })
        .catch(error => {
          console.log(error)
          NotifyService.setErrorMessage(this.$t('eshop.notify.export_failed') + ' (' + error + ')')
        })
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['feUserPollVote/filter']
  }
}
</script>

<style lang="scss">

</style>
